// /* Slider test*/
@media (min-width: 1000px) {
    #modal-xl.modal-lg {
        max-width: calc(100% - 10%) !important;
    }
    #modal-lg.modal-lg {
        max-width: calc(90% - 10%) !important;
    }
}

.ag-watermark {
    visibility: hidden !important;
}

.ag-watermark-text {
    visibility: hidden !important;
}
