.rag-red {
    background-color: #f8aaaa !important;
}

.rag-red-variant {
    // background-color: #f8aaae !important;
    background-color: #f8aaaa !important;    
}

.rag-red-altern {
    // background-color: #ffa5a9 !important;
    background-color: #f8aaaa !important;    
}

.rag-red-hard {
    // background-color: #fc8484 !important;
    background-color: #f8aaaa !important;
}

.rag-red-others {
    // background-color: #fc8484 !important;
    background-color: #f8aaaa !important;
}

.rag-green {
    background-color: #94f8a5 !important;
}

.rag-green-variant {
    background-color: #94f8a5 !important;
    // background-color: #94f8a4 !important;
}

.rag-green-hard {
    background-color: #94f8a5 !important;
    // background-color: #30fb53 !important;
}

.rag-yellow {
    background-color: #fffeaa !important;
}

.rag-yellow-variant {
    // background-color: #fffeae !important;
    background-color: #fffeaa !important;    
}

.rag-gray {
    background-color: #fdfdfd !important;
}

.rag-blue {
    background-color: #d4eaff !important;
}

.rag-blue-hard {
    background-color: #5fbdff !important;
}

.no-header {
    .card-header {
        margin-bottom: -23px !important;
    }
}

.rag-orange {
    // background-color: #ffd5aa !important;
    background-color: #f8aaaa !important;
}

.bg-refrigerated-container {
    background-color: #94f8a5 !important;
}

.strong {
    font-weight: bold !important;
}
// .ag-center-cols-clipper {
//     min-height: unset !important;
// }

.ag-theme-alpine .ag-ltr .ag-cell {
    font-size: 12px;
    // line-height: 3;
}
// .ag-cell-value {
//     line-height: 2.5;
// }

.ag-unselectable {
    -moz-user-select: auto !important;
    -webkit-user-select: auto !important;
    user-select: auto !important;
}

.rag-violet {
    background-color: #B2A2D3 !important;
}