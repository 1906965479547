// ========== para la impresion ==============
@media print {
  html, body {
    width: 100%;
    // height: 100%;
  }
  .component-to-print {
    width: 100%;
    margin: 0;
    padding: 0;
  }
	.modal-content{
    width: 100%;
    margin: 0;
    padding: 0;
	}
	// .contenido-pdf{
  //   width: 100%;
  //   margin: 0;
  //   padding: 0;
	// 	font-family: "Times New Roman", Times, serif;
	// 	font-size: 11px;		
	// }
	// .comment-box{
  //   width: 100%;
  //   margin: 0;
  //   padding: 0;
	// 	div.media{
	// 		width: 95%;
	// 		margin: 0;
	// 		padding: 0;
	// 	}
	// }
}

// ====== fin de la función print ========

body {
  // font-size: 13px; /* Este valor es sólo un ejemplo, puedes ajustarlo a tu gusto */
	font-size: 0.8em;
}

.ag-theme-alpine .ag-header {
	background-color: #f8f8f8;
	background-color: var(--ag-header-background-color, #f8f8f8);
	border-bottom: solid 1px;
	border-bottom-color: #babfc7;
	border-bottom-color: var(--ag-border-color, #babfc7);
	font-size: 0.9em !important
}


.contenido-pdf {
  font-family: "Times New Roman", Times, serif;
  font-size: 10px;
}


.oversized-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

// .oversized-list {
//   column-count: 3;
//   column-gap: 10px;
// }


.oversized-list li {
  // background-color: #f5f5f5;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.oversized-list li h6 {
  font-weight: bold;
  margin: 0;
  font-size: 16px;
}

.checkbox-large input[type="checkbox"] {
  transform: scale(2);
}

.d-info{
	// border: 1px solid orange !important;
}
.qr-container {
  height: 0;
  padding-bottom: 100%;
	// border: 1px solid red !important;
	width: 120px;
	height: 120px;
  // position: absolute;
}

.qr-info {
	// border: 1px solid magenta !important;
	position: absolute;
	top: 0;
	right: 0;
	width: 35%;
	height: auto;
}

.qr-info > svg {
	// border: 1px solid greenyellow !important;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-public{
	.header-public-content{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		padding-top: 15px;
		.title{
			width: 30%;
		}
		.img-logo{			
			width: 40%;
			img {
				width: 250px;
				height: auto;
				margin: 0px auto;
				text-align: center;
				padding: 0px;
			}
		}	
	}
	.footer-compas{
		p{
			color: #fff !important;
		}
	}
}

.footer-compas{
	background-color: #21284a !important;
	// background-color: #030a8c !important;
	// background-color: #2e3192 !important;
	height: auto;
	padding-bottom: 5px !important;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	.container-fluid{
		.row {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			width: 100% !important;
			.content-img-ccto{
				width: 100% !important;
				min-height: 100px !important;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				margin: 0px auto !important;
				text-align: center;
				padding-bottom: 10px;
				img.full-img-compas {
					width: 350px;
					height: auto;
					margin: 0px auto;
					text-align: center;
					padding: 0px;
					position: relative;
					border-radius: 2%;
				}
			}	
			.texto{
				width: 100% !important;
				clear: both;
				p{
					font-size: 1.3em;
				}
			}
			.footer-copyright{
				width: 100% !important;
				text-align: center !important;
				margin: 0px auto;
				padding-bottom: 20px !important;
				font-size: 1.0em;
				color: #030a8c;
			}
		}
	}
}

.body-recupera{
	// border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;	
	img {
		width: 100%;
		max-width: 400px; /* ajusta el valor máximo según tus necesidades */
		height: auto;
	}	
	h6{
		line-height: 1.5em !important;
	}
}

.nada{
	background-color: #f9f9f9 !important;
	border-color: #f9f9f9 !important;
}

.frag{
	// border: 2px solid magenta;
	margin: 0px auto;
	padding: 15px 5px;
	.content-img-ccto{
		// border: 2px solid orange;
		overflow: hidden;
		.full-img-compas {
			width: 100%;
			height: 100%;
			object-fit: cover;
			margin: 0px;
			padding: 0px;
		}
	}
}


.inputs-inline {
	// border: 2px solid orange !important;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	label {
		width: 100% !important;
	}
	input {
		width: calc(100% / 8);
	}
}

.form-control-plaintext {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da !important;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	color: #b0b4b9 !important;
	/*#495057*/
}

.modal-footer-ccto {
	// border: 1px solid orange !important;
	justify-content: space-between !important;

	button.naranja {
		background-color: $naranja-ccto !important;
		border: 1px solid $naranja-ccto !important;

		&:hover {
			background-color: $naranja-ccto-oscuro !important;
		}

		svg {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}

		span {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}
	}

	button.izquierdo-file {
		background-color: $naranja-ccto !important;
		border: 1px solid $naranja-ccto !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		padding: 4px 12px !important;

		&:hover {
			background-color: $naranja-ccto-oscuro !important;
		}

		svg {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}

		span {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}
	}
}

.hr-nomore {
	background: none !important;
	border-color: none !important;
	border-top: 1px solid none !important;
}

.noshow {
	display: none !important;
	visibility: hidden;
}

.file-scroll {
	overflow: overlay !important;
}

.div-margin {
	margin-top: 30px;
	margin-bottom: 30px;
}

.div-contents {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;

	.naranja {
		background-color: $naranja-ccto !important;
		border: 1px solid $naranja-ccto !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		padding: 4px 6px !important;

		&:hover {
			background-color: $naranja-ccto-oscuro !important;
		}

		svg {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}

		span {
			// border: 1px solid blue !important;
			padding: 3px !important;
			margin: 0px !important;
			color: white !important;
		}
	}
}

// label[for="esimmo"] .form-check-label {
//   padding-left: 10px;
// }

label .form-check-label {
  padding-left: 10px;
}

.p-without-text {
  // visibility: hidden;
	// border: 1px solid red;
	color: white !important;
}

.checkbox-large input[type="checkbox"] {
  transform: scale(1.5);
}
