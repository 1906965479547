.div-add-booking {
    // border: 1px solid red !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    flex-wrap: wrap;
    padding-right: 17px;
    button {
        border-radius: 3px;
        cursor: pointer;
        vertical-align: middle;
        background-color: $naranja-ccto !important;
        border: 1px solid $naranja-ccto !important;
        color: white;
        .text-danger {
            color: white !important;
        }
    }
    button.btn-add-new-booking {
        border-radius: 3px;
        cursor: pointer;
        vertical-align: middle;
        background-color: $naranja-ccto !important;
        border: 1px solid $naranja-ccto !important;
        color: white;
        padding-right: 22px;
        padding-bottom: 5px;
        outline: none;
        .text-danger {
            color: white !important;
        }
    }

    button.btn-add-new-action {
        align-content: center;
        align-items: center;
        border-radius: 3px;
        color: white;
        background-color: $naranja-ccto !important;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 5px !important;
        outline: none;
        padding: 4px 6px;
        .text-danger {
            color: white !important;
        }
        svg {
            padding: 3px !important;
            margin: 0px !important;
        }
        span {
            padding: 3px !important;
            margin: 0px !important;
        }
        &:hover {
            background-color: $naranja-ccto-oscuro !important;
            transition: 0.5s ease;
        }
    }
}

.report-bookig {
    .card {
        .card-body {
            .outer {
                .ag-theme-alpine {
                    div {
                        div.ag-root-wrapper.ag-layout-normal.ag-ltr {
                            div.ag-root-wrapper-body {
                                div.ag-root {
                                    div.ag-body-viewport {
                                        div.ag-center-cols-clipper {
                                            div.ag-center-cols-viewport {
                                                div.ag-center-cols-container {
                                                    div {
                                                        div.ag-react-container {
                                                            strong {
                                                                // border: 3px solid greenyellow;
                                                                margin: 0px !important;
                                                                padding: 0px !important;
                                                                svg {
                                                                    // border: 1px solid orangered !important;
                                                                    margin: 0.5rem !important;
                                                                    padding: 0px !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// ============= booking manual ==================
.crear-manual {
    .table-quantity {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .div-add-containers-quantity {
        // border: 3px solid magenta !important;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        padding-right: 0px;
        button.btn-add-new-action {
            border-radius: 3px;
            cursor: pointer;
            // vertical-align: middle;
            background-color: $naranja-ccto !important;
            border: 1px solid $naranja-ccto !important;
            color: white;
            // padding-right: 22px;
            padding: 4px 6px;
            outline: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            .text-danger {
                color: white !important;
            }
            svg {
                // border: 1px solid blue !important;
                padding: 3px !important;
                margin: 0px !important;
            }
            span {
                // border: 1px solid blue !important;
                padding: 3px !important;
                margin: 0px !important;
            }
            &:hover {
                background-color: $naranja-ccto-oscuro !important;
                transition: 0.5s ease;
            }
        }
    }
}
