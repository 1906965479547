@mixin icon-login {
    div.input-group {
        div.input-group-prepend {
            display: none;
        }
        div {
            span {
                i {
                    color: $naranja-ccto !important;
                }
            }
        }
        input {
            color: $azul-ccto !important;
            border: 1px solid $azul-ccto !important;
        }
    }
}

@mixin label-text-login() {
    label {
        color: $azul-ccto !important;
        width: 100% !important;
    }
}

// media queries
// @media screen and (max-width: 768px) {}
// @media (min-width: 768px) {}
// @media (min-width: 1024px) {}
// @media (min-width: 1350px) {}

@mixin login($size-wrap, $size-div-one, $size-div-two, $direccion, $opacidad) {
    .App {
        .page-wrapper {
            .auth-bg {
                .authentication-box {
                    width: $size-wrap !important;
                    position: relative;
                    div.bg {
                        // position: absolute;
                        // background-color: orange !important;
                        // opacity: .2;
                        background-color: white !important;
                        opacity: $opacidad;
                        border-radius: 0.25rem;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        width: 100%;
                        height: 100%;
                    }
                    div.card {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        min-width: 0;
                        word-wrap: break-word;
                        background: none !important;
                        // background-clip: border-box;
                        // border: 1px solid rgba(0, 0, 0, 0.125);
                        border-radius: 0.25rem;
                        .card-body {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            align-content: center;
                            flex-wrap: wrap;
                            flex-direction: $direccion;
                            .text-center {
                                width: $size-div-one !important;
                                h5 {
                                    color: $azul-ccto !important;
                                    text-transform: uppercase;
                                    text-align: center;
                                    margin: 0px auto 5px auto;
                                }
                                div {
                                    // margin-top: 10px;
                                    margin: 0px auto 5px auto;
                                    text-align: center;
                                    img {
                                        display: block;
                                        margin: 0px auto;
                                        width: 100% !important;
                                        height: auto;
                                    }
                                }
                            }
                            .theme-form {
                                width: $size-div-two !important;
                                .div-user {
                                    @include icon-login();
                                    @include label-text-login();
                                }
                                .div-pass {
                                    @include icon-login();
                                    @include label-text-login();
                                }
                                .div-remember-me {
                                    label {
                                        padding-left: 20px;
                                        &::before {
                                            left: 5px;
                                            border-color: $azul-ccto !important;
                                        }
                                    }
                                }
                                .btn-login-ccto {
                                    button.btn-warning {
                                        background-color: $azul-ccto !important;
                                        border-color: $azul-ccto !important;
                                        transition: 0.5s ease;
                                        &:hover {
                                            // background-color: $naranja-ccto !important;
                                            background-color: $gris-ccto !important;
                                            border-color: $azul-ccto !important;
                                            color: $azul-ccto !important;
                                        }
                                    }
                                }
                                .div-forgot-my-pass {
                                    button {
                                        transition: 0.5s ease;
                                        border-color: white !important;
                                        border-color: $gris-ccto !important;
                                        color: $azul-ccto !important;
                                        &:hover {
                                            color: white !important;
                                            border-color: $azul-ccto !important;
                                            background-color: $azul-ccto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // =================== fin estilos para el login =========================
        }
    }
}

// media queries
@media screen and (max-width: 768px) {
    @include login(70%, 85%, 100%, column, 0.9);
}

@media (min-width: 768px) {
    @include login(60%, 70%, 100%, column, 0.9);
}

@media (min-width: 1024px) {
    @include login(65%, 47%, 51%, row, 0.85);
}

@media (min-width: 1350px) {
    @include login(60%, 62%, 38%, row, 0.85);
}
