.wrapper-ccto {
	.page-main-header.header-ccto {
		// background-color: $gris-ccto !important;
		background-color: white !important;
		.main-header-right {
			.icon-visible-ccto {
				// border: 1px solid magenta !important;
				.media-body {
					label.switch {
						margin-left: 20px !important;
						a {
							svg {
								// background-color: magenta !important;
								// color: magenta !important;
								line {
									background-color: $naranja-ccto !important;
									color: $naranja-ccto !important;
								}
							}
						}
						span {
							cursor: pointer;
							svg {
								// background-color: magenta !important;
								// color: magenta !important;
								line {
									background-color: $naranja-ccto !important;
									color: $naranja-ccto !important;
								}
							}
						}
					}
				}
			}
			.nav-ccto {
				background-color: white !important;
				ul.nav-menus {
					background-color: white !important;
					li {
						li#Popover {
							div {
								ul.right-name-profile {
									li {
										display: flex;
										justify-content: space-around;
										align-items: center;
										align-content: center;
										div {
											color: $azul-ccto;
										}
										img.img-profile {
											border-radius: 50% !important;
											height: 2rem;
											width: 2rem;
											border: 1px solid $azul-ccto;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

div.popover {
	display: block;
	.popover-inner {
		display: block;
		.popover-body {
			display: block;
			.media {
				display: block;
				ul.profile-dropdown {
					li.close-session-ccto {
						// border: 1px solid magenta !important;
						div.btn-close {
							vertical-align: middle;
							cursor: pointer;
							color: $azul-ccto !important;
							display: flex;
							justify-content: space-between;
							align-items: center;
							align-content: center;
							svg {
								margin-right: 2px;
							}
							span {
								margin-left: 6px;
							}
						}
					}
				}
			}
		}
	}
}
