.page-wrapper{
    .wrapper-ccto{
        .page-main-header{
            div.main-header-right{
                div.mobile-sidebar{
                    // border: 3px solid cyan !important;
                    div.media-body{
                        // link para ocultar menu
                        label{
                            // border: 3px solid greenyellow !important;
                            height: 25px !important;
                            // height: ;
                            /*
                            w:25
                            h:16
                            */
                            a{
                                svg{
                                    // color: aqua !important;
                                    g{
                                        // color: greenyellow !important;
                                        path{
                                            // color: orange !important
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .body-ccto{
            // border: 10px solid magenta !important;
            background-color: $gris-ccto !important;
            .wellcome{
                background-color: $gris-ccto !important;
                padding: 0px !important;
                div{
                    background-color: $gris-ccto !important;
                    // padding: 30px 100px;
                    h4{
                        color: $azul-ccto !important;
                        text-align: center;
                        text-transform: uppercase;
                        font-family: 'open_sansregular';
                        margin-bottom: 20px;
                        font-size: 1.2rem !important;
                    }
                    // img{
                    //     padding: 0px !important;
                    //     background-repeat: no-repeat;
                    //     background-position: top center;
                    //     display: flex;
                    //     align-items: center;
                    //     justify-content: center;
                    //     width: 90% !important;
                    //     max-width: 90% !important;
                    //     height: auto;
                    // }
                }
            }
        }
    }
}