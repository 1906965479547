// .noshow{
//     color: white !important;
//     border: 2px solid orange;
// }
.page-wrapper {
    .wrapper-ccto {
        .div-sidebar {
            div.main-header-left {
                background-color: white;
                // border: 1px solid magenta ;
                color: white;
            }
            div.sidebar.custom-scrollbar {
                background-color: $azul-ccto;
                // border: 1px solid greenyellow ;
                color: white;
                ul {
                    li {
                        text-decoration: none;
                        list-style: none;
                        a {
                            font-family: 'open_sansregular';
                            color: white;
                        }
                        p {
                            font-family: 'open_sansregular';
                            color: white;
                            cursor: pointer;
                        }
                        h3 {
                            font-family: 'open_sansregular';
                            color: white;
                            cursor: pointer;
                        }
                        ul {
                            li {
                                text-decoration: none;
                                list-style: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.text-active {
    color: #f87849 !important;
}

/*
    $azul-ccto
    $naranja-ccto
    $gris-ccto
*/
