.swal2-title {
    font-size: 1.375em !important;
}

.swal2-content {
    font-size: 1.025em !important;
}

.swal2-styled.swal2-confirm {
    font-size: 0.9em !important;
    background-color: $primary !important;
}

.swal2-styled.swal2-cancel {
    font-size: 0.9em !important;
    // background-color: #efefef00 !important;
    color: black !important;
    background: #e5e5e5 !important;
    &:hover,
    &:focus {
        background: #bfbfbfd8 !important;
    }
}

.swal2-icon.swal2-warning {
    border-color: $warning !important;
    color: $warning !important;
}
